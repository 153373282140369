import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useI18n } from "../../spages/spa/context/I18nContext";
import CalendarSvg from "./CalendarSvg";
import "./FlexibleDaysAvailability.scss";

const propTypes = {
  flexibleMoveInDate: PropTypes.string.isRequired,
  flexibleMoveOutDate: PropTypes.string,
};

const FlexibleDaysAvailability = ({
  flexibleMoveInDate,
  flexibleMoveOutDate,
}) => {
  const { t } = useI18n();

  const formatDate = (date) => (date ? dayjs(date).format("MMM D") : null);

  return (
    <div className="FlexibleDaysAvailability">
      <CalendarSvg />
      <span
        className="FlexibleDaysAvailability-duration"
        data-testid="FlexibleDaysAvailability-duration"
      >
        {flexibleMoveOutDate
          ? `${formatDate(flexibleMoveInDate)} - ${formatDate(flexibleMoveOutDate)}`
          : t("listings.flexibleAvailabilityFrom", {
              date: formatDate(flexibleMoveInDate),
            })}
      </span>
    </div>
  );
};

FlexibleDaysAvailability.propTypes = propTypes;

export default FlexibleDaysAvailability;
