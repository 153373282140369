import React from "react";
import PropTypes from "prop-types";

const ShortlistSvg = ({ width = 18, height = 18 }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16.5625L7.9125 15.5725C4.05 12.07 1.5 9.75999 1.5 6.92499C1.5 4.61499 3.315 2.79999 5.625 2.79999C6.93 2.79999 8.1825 3.40749 9 4.36749C9.8175 3.40749 11.07 2.79999 12.375 2.79999C14.685 2.79999 16.5 4.61499 16.5 6.92499C16.5 9.75999 13.95 12.07 10.0875 15.58L9 16.5625Z"
        fill="#fff"
        stroke="#575D64"
      />
    </svg>
  );
};

ShortlistSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ShortlistSvg;
