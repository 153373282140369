import React from "react";
import PropTypes from "prop-types";

const CalendarSvg = ({ width = 20, height = 19 }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 2.49994H3C1.89543 2.49994 1 3.39537 1 4.49994V15.4999C1 16.6045 1.89543 17.4999 3 17.4999H17C18.1046 17.4999 19 16.6045 19 15.4999V4.49994C19 3.39537 18.1046 2.49994 17 2.49994Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.33002 2.5V0"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M13.67 2.5V0"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.5 10.23C7.05228 10.23 7.5 9.78233 7.5 9.23004C7.5 8.67776 7.05228 8.23004 6.5 8.23004C5.94772 8.23004 5.5 8.67776 5.5 9.23004C5.5 9.78233 5.94772 10.23 6.5 10.23Z"
        fill="black"
      />
      <path
        d="M10.01 10.23C10.5623 10.23 11.01 9.78233 11.01 9.23004C11.01 8.67776 10.5623 8.23004 10.01 8.23004C9.45772 8.23004 9.01001 8.67776 9.01001 9.23004C9.01001 9.78233 9.45772 10.23 10.01 10.23Z"
        fill="black"
      />
      <path
        d="M13.5 10.23C14.0523 10.23 14.5 9.78233 14.5 9.23004C14.5 8.67776 14.0523 8.23004 13.5 8.23004C12.9477 8.23004 12.5 8.67776 12.5 9.23004C12.5 9.78233 12.9477 10.23 13.5 10.23Z"
        fill="black"
      />
      <path
        d="M6.5 13.72C7.05228 13.72 7.5 13.2723 7.5 12.72C7.5 12.1677 7.05228 11.72 6.5 11.72C5.94772 11.72 5.5 12.1677 5.5 12.72C5.5 13.2723 5.94772 13.72 6.5 13.72Z"
        fill="black"
      />
      <path
        d="M10.01 13.72C10.5623 13.72 11.01 13.2723 11.01 12.72C11.01 12.1677 10.5623 11.72 10.01 11.72C9.45772 11.72 9.01001 12.1677 9.01001 12.72C9.01001 13.2723 9.45772 13.72 10.01 13.72Z"
        fill="black"
      />
      <path
        d="M2.40002 2.90002H17.6C18.15 2.90002 18.6 3.35002 18.6 3.90002C18.6 5.00002 17.7 5.90002 16.6 5.90002H3.40002C2.30002 5.90002 1.40002 5.00002 1.40002 3.90002C1.40002 3.35002 1.85002 2.90002 2.40002 2.90002Z"
        fill="#EDF1F4"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17 2.49994H3C1.89543 2.49994 1 3.39537 1 4.49994V15.2499C1 16.3545 1.89543 17.2499 3 17.2499H17C18.1046 17.2499 19 16.3545 19 15.2499V4.49994C19 3.39537 18.1046 2.49994 17 2.49994Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

CalendarSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CalendarSvg;
