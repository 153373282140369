import dayjs from "dayjs";

const formatDatePreviews = ({
  fromDate,
  toDate,
  defaultFromString,
  defaultToString,
  isFullDateFormat = true,
}) => {
  const dateFormat = isFullDateFormat ? "MMM D, YYYY" : "MMM D";

  const fromDatePreview = fromDate
    ? dayjs(fromDate).format(dateFormat)
    : defaultFromString;

  const toDatePreview = toDate
    ? dayjs(toDate).format(dateFormat)
    : defaultToString;

  return { fromDatePreview, toDatePreview };
};

const isoFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD") : null;
};

const getWeekdaysStartingFromMonday = () => {
  const daysOfWeek = dayjs.weekdaysMin();
  const mondayIndex = 1;
  return daysOfWeek.slice(mondayIndex).concat(daysOfWeek.slice(0, mondayIndex));
};

export { formatDatePreviews, isoFormat, getWeekdaysStartingFromMonday };
