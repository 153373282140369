import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useAuth } from "../../contexts/AuthContext";
import { ShortlistContext } from "../../contexts/ShortlistContext";
import { useAnalytics } from "../../spages/spa/context/AnalyticsContext";
import ShortlistPageContext from "../../spages/spa/Pages/Shortlist/ShortlistPageContext";
import pushToDataLayer from "../../utils/shortlist/data-layer";
import ExtraAreaButton from "../ExtraAreaButton/ExtraAreaButton";
import toaster from "../Toaster/Toaster";
import ShortlistSvg from "./ShortlistSvg";

const propTypes = {
  groupId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  hasLabel: PropTypes.bool,
  className: PropTypes.string,
  withToaster: PropTypes.bool,
  eventLabel: PropTypes.string,
};

const displaySavedToaster = (t, url) => {
  toaster.basicToaster.add({
    text: t("components.ShortlistButton.toasterSuccess"),
    timeout: 3000,
    buttonLabel: t("components.ShortlistButton.toasterViewButton"),
    buttonLink: url("shortlist"),
    intent: toaster.intents.brand,
  });
};

const displayErrorToaster = (t) => {
  toaster.basicToaster.add({
    text: t("pages.ShortlistPage.error.fullList"),
    timeout: 3000,
    intent: toaster.intents.danger,
    hasButton: false,
  });
};

const MAX_SHORTLIST_COUNT = 30;

const ShortlistButton = ({
  hasLabel = false,
  className,
  groupId,
  withToaster,
  t,
  url,
  eventLabel,
  ...props
}) => {
  const { shortlist } = React.useContext(ShortlistContext);
  const analytics = useAnalytics();
  const { user } = useAuth();
  const isActive = shortlist?.list?.some((item) => item.groupId === groupId);
  const [wasRemovedFromShortlist, setWasRemovedFromShortlist] =
    React.useState(false);
  const shortlistPageContext = React.useContext(ShortlistPageContext);

  const event =
    eventLabel && analytics ? analytics.category("Shortlist") : null;

  return (
    <ExtraAreaButton
      data-groupid={groupId}
      tabIndex="0"
      onClick={(e) => {
        e.stopPropagation();
        if (!isActive && shortlist.list.length >= MAX_SHORTLIST_COUNT) {
          displayErrorToaster(t);
          return;
        }

        shortlist.toggle(groupId).then(() => {
          if (withToaster && !isActive) {
            displaySavedToaster(t, url);
          }

          if (shortlistPageContext) {
            shortlistPageContext.updateListings(groupId);
          }

          const hasOwnShortlist = shortlist.list.length > 0;
          pushToDataLayer(hasOwnShortlist, user);

          if (isActive) {
            setWasRemovedFromShortlist(true);
          } else {
            setWasRemovedFromShortlist(false);
          }

          if (event) {
            const eventAction = isActive ? "Removed Listing" : "Added Listing";
            event(eventAction, eventLabel);
          }
        });
      }}
      className={classnames(
        "ShortlistButton",
        { noLabel: !hasLabel, isActive, wasRemovedFromShortlist },
        className,
      )}
      {...props}
    >
      <ShortlistSvg />
      {hasLabel && (
        <span className="ShortlistButton-label">
          {isActive
            ? t("spages.listing.ListingDetailsPageBase.shortlistSaved")
            : t("spages.listing.ListingDetailsPageBase.shortlistSave")}
        </span>
      )}
    </ExtraAreaButton>
  );
};

ShortlistButton.propTypes = propTypes;

export default ShortlistButton;
